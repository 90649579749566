<template>
    <div>
        <RoleManagement_Component></RoleManagement_Component>
    </div>
</template>
<!--  -->
<script>
import RoleManagement_Component from '../../components/PersonalCenter/RoleManagement_Component.vue'
export default {
    name: "RoleManagement",
    components: { RoleManagement_Component }
}
</script>

<style>

</style>