<template>
    <!-- MyAddress -->
    <div class="role">
        <div class="roleBtn">
            <el-button type="primary" icon="el-icon-search">搜索</el-button>
            <el-button type="primary" icon="el-icon-s-custom">新增</el-button>
        </div>

        <div class="tabs">
            <el-table :data="tableData" style="width: 100%" max-height="250">
                <el-table-column fixed prop="roleName" label="角色名称" width="850">
                </el-table-column>

                <el-table-column fixed="right" label="操作" width="264" class-name="nana">
                    <template slot-scope="scope">
                        <el-button @click.native.prevent="modifyRow(scope.$index, scope.row)" type="text" size="small">
                            修改
                        </el-button>
                        <el-button @click.native.prevent="deleteRow(scope.$index, tableData)" type="text" size="small">
                            移除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
export default {
    name: "RoleManagement_Component",
    data() {
        return {
            tableData: [
                {
                    roleName: '租箱管理',
                },
                {
                    roleName: '回箱管理',
                },
                {
                    roleName: '订舱管理',
                },
            ]
        }
    },
    methods: {
        modifyRow(index, row) { //修改
            // console.log(index);
            // console.log(row.roleName);
        },
        deleteRow(index, rows) {//移除
            rows.splice(index, 1)
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep .el-table_1_column_1 {
    .cell {
        font-size: 14px;
        font-weight: bold;
        color: #707070;
    }


}

::v-deep .el-table__row .el-table__cell {
    .cell {
        font-size: 14px;
        font-weight: bold;
        color: #333333;
    }
}



.role {
    margin: 0 auto;
    max-width: 1526px;
    height: 520px;
    background: #FFFFFF;
    border-radius: 8px 8px 8px 8px;
    padding-top: 24px;
    padding-left: 32px;

    .roleBtn {
        // padding-left: 32px;

        .el-button {
            width: 120px;
            height: 40px;
            background: #1890FF;
            box-shadow: 0px 3px 6px 1px rgba(24, 144, 255, 0.3);
            border-radius: 4px 4px 4px 4px;
        }

        .el-button:nth-child(2) {
            margin-left: 24px;
        }
    }

    .tabs {
        margin-top: 59px;

        ::v-deep .nana {
            text-align: center;
        }

        ::v-deep .el-table_1_column_2 {
            text-align: center;
        }

        .el-button {
            padding: 6px 12px;
            background: #1890FF;
            box-shadow: 0px 3px 6px 1px rgba(24, 144, 255, 0.3);
            border-radius: 4px 4px 4px 4px;
            font-size: 12px;
            font-weight: 400;
            color: #FFFFFF;
        }

        .el-button:nth-child(2) {
            background: #D4252C;
            box-shadow: 0px 3px 6px 1px rgba(212, 37, 44, 0.3);
        }
    }
}
</style>